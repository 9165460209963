<template>
    <div>
        <CrudTable :route="'gender'" :title="'Гендоры'" :headers="headers" :array_name="'genders'" :item="editedItem">
        </CrudTable>
    </div>
</template>
<script>
import CrudTable from '@/components/CrudTable.vue';
export default {
    name: 'GenderView',
    components: {
        CrudTable
    },
    data: () => ({
        headers: [
            {
                text: 'ID',
                align: 'start',
                sortable: false,
                value: 'id',
            },
            { text: 'Название пола', value: 'name' },
        ],
        desserts: [],
        editedItem: {
            id: 0,
            name: ''
        },
    })
}
</script>