<template>
    <v-card>
        <v-card-title>
            Пользователи
            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="desserts" :search="search"></v-data-table>
        <v-pagination @input="pageChange" v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
    </v-card>
</template>
<script>
export default {
    name: 'UsersView',
    data: () => ({
        headers: [
            {
                text: 'ID',
                align: 'start',
                sortable: false,
                value: 'id',
            },
            { text: 'Username', value: 'username' },
            { text: 'Телефон', value: 'phone' },
            { text: 'Пол', value: 'gender.name' },
            { text: 'Город', value: 'city.name' },
            { text: 'Зарегистрирован', value: 'created_at' },
        ],
        search: '',
        page: 1,
        desserts: [],
        itemsPerPage: 10,
        pageCount: 100,
    }), methods: {
        pageChange() {
            this.getItems(this.page)
        },
        getItems(p = 1) {
            this.axios.get('/admin/user/', { params: { page: p } }).then(response => {
                this.desserts = response.data['items']
                this.pageCount = response.data.total_pages
            })
        },
    },
    mounted() {
        this.getItems()
    }
}
</script>