<template>
    <div>
        <v-data-table :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer :headers="headers_title"
            :items="desserts" sort-by="calories" class="elevation-1">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>{{ title }}</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="500px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                                Добавить элемент
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                                <span class="text-h5">{{ formTitle }}</span>
                            </v-card-title>

                            <v-card-text> <v-form ref="form">
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12" sm="12" md="12" v-for="(h, i) in headers.slice(1)" :key="i">
                                                <v-autocomplete v-if="h.model" :items="h.dicts" :label="h.text"
                                                    item-text="name" item-value="id" v-model="editedItem[h.model]">
                                                </v-autocomplete>
                                                <v-textarea v-else-if="h.textarea" v-model="editedItem[h.value]"
                                                    :label="h.text"></v-textarea>
                                                <v-checkbox v-else-if="h.bool" :label="h.text"
                                                    v-model="editedItem[h.value]"></v-checkbox>
                                                <v-text-field v-else-if="h.number" v-model="editedItem[h.value]"
                                                    :label="h.text" :rules="validation_rules['number']"></v-text-field>
                                                <v-text-field v-else v-model="editedItem[h.value]" :label="h.text"
                                                    :rules="validation_rules['string']"></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12"
                                                v-if="array_name === 'analyses' && editedItem['is_result_bool']">
                                                <v-radio-group v-model="is_result_bool" inline>
                                                    <v-radio label="Референсный резултат: Отрицательно" value="0"></v-radio>
                                                    <v-radio label="Референсный резултат: Положительно" value="1"></v-radio>
                                                </v-radio-group>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-form>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="close">
                                    Отмена
                                </v-btn>
                                <v-btn color="blue darken-1" text @click="save">
                                    Сохранить
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                            <v-card-title class="text-h5">Вы уверены что хотите удалить?</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="closeDelete">Отмена</v-btn>
                                <v-btn color="blue darken-1" text @click="deleteItemConfirm">Удалить</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                </v-icon>
                <v-icon small @click="deleteItem(item)">
                    mdi-delete
                </v-icon>
            </template>
        </v-data-table>
        <v-pagination @input="pageChange" v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
    </div>
</template>
<script>

export default {
    name: 'CrudTable',
    props: [
        'title',
        'headers',
        'item',
        'route',
        'array_name'
    ],
    data: () => ({
        page: 1,
        desserts: [],
        itemsPerPage: 10,
        pageCount: 100,
        dialog: false,
        dialogDelete: false,
        headers_title: [],
        editedIndex: -1,
        editedItem: {
        },
        defaultItem: {
        },
        is_result_bool: '0',

        validation_rules: {
            "number": [
                v => !!v || 'Поле не должно быть пустым',
                v => !isNaN(v) || 'Должно быть числом'
            ],
            "string": [
                v => !!v || 'Поле не должно быть пустым',
                v => v.length < 255 || 'Менее 255 символов'
            ]
        }
    }),

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'Создание элемента' : 'Редактирование элемента'
        },
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },

    },

    created() {
        this.editedItem = Object.assign({}, this.item)
        this.defaultItem = Object.assign({}, this.item)
        this.headers_title = Object.assign([], this.headers)
        this.headers_title.push({ text: 'Действия', value: 'actions', sortable: false })
        this.getItems()
    },

    methods: {
        pageChange() {
            this.getItems(this.page)
        },
        getItems(p = 1) {
            this.axios.get(this.route + '/', { params: { page: p } }).then(response => {
                this.desserts = response.data['items']
                if (this.array_name == 'cities') {
                    this.desserts.forEach(i => {
                        i.region_id = i.region.id
                    })
                }
                this.pageCount = response.data.total_pages
            })
        },
        editItem(item) {
            this.editedIndex = this.desserts.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        deleteItem(item) {
            this.editedIndex = this.desserts.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        deleteItemConfirm() {
            this.desserts.splice(this.editedIndex, 1)
            this.axios.delete(this.route + '/' + this.editedItem.id)
            this.closeDelete()
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        save() {
            if (this.$refs.form.validate()) {
                if (this.array_name === 'analyses' && this.editedItem['is_result_bool']) {
                    this.editedItem['ref_min'] = this.is_result_bool == '0' ? 0 : 1
                    this.editedItem['ref_max'] = this.is_result_bool == '0' ? 0 : 1
                }
                if (this.editedIndex > -1) {
                    Object.assign(this.desserts[this.editedIndex], this.editedItem)
                    this.axios.put(this.route + '/' + this.editedItem.id, this.editedItem)
                } else {
                    this.axios.post(this.route + '/', this.editedItem).then(response => {
                        this.desserts.push(response.data)
                    })
                }

                this.close()
            }
        },
    },
}
</script>