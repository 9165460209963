<template>
    <div class="hold-transition login-page">
        <div class="login-box">
            <div class="login-logo">
                <a href="#"><b>Partician Admin</b></a>
            </div>
            <div class="card">
                <div class="card-body login-card-body">
                    <p class="login-box-msg">Необходимо войти чтобы продолжить</p>

                    <form ref="login-form" @submit="login">
                        <div class="input-group mb-3">
                            <input v-model="username" type="text" class="form-control" placeholder="Username" required>
                            <div class="input-group-append">
                                <div class="input-group-text">
                                    <span class="fas fa-envelope"></span>
                                </div>
                            </div>
                        </div>
                        <div class="input-group mb-3">
                            <input v-model="password" type="password" class="form-control" placeholder="Password" required>
                            <div class="input-group-append">
                                <div class="input-group-text">
                                    <span class="fas fa-lock"></span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <button type="submit" class="btn btn-primary btn-block">Войти</button>
                            </div>
                            <div class="col-12" v-if="error_text">
                                <div class="alert alert-danger" role="alert">
                                    {{ error_text }}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'LoginView',
    data() {
        return {
            username: null,
            password: null,
            error_text: null
        }
    },
    methods: {
        login(form) {
            if (this.username && this.password) {
                this.axios.post('https://auth.api.patrician.one/admin_user/login', {
                    username: this.username,
                    password: this.password
                }).then(response => {
                    localStorage.setItem('JWT_token', response.data.token)
                    localStorage.setItem('exp', response.data.exp)
                    this.$router.push({ name: 'dashboard' })
                }).catch(e => {
                    this.error_text = e?.response?.data?.detail ?? 'Произошла ошибка'
                })
            }
            form.preventDefault();
        }
    }
}
</script>
