<template>
    <div>

    </div>
</template>
<script>
export default {
    name: 'DashboardView',
    methods: {

    },
}
</script>