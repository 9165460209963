<template>
  <div class="wrapper" v-if="$route.name !== 'login'">
    <nav class="main-header navbar navbar-expand navbar-white navbar-light justify-content-end">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" data-widget="fullscreen" href="#" role="button">
            <i class="fas fa-expand-arrows-alt"></i>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-widget="control-sidebar" data-slide="true" href="#" role="button">
            <i class="fas fa-th-large"></i>
          </a>
        </li>
      </ul>
    </nav>

    <aside class="main-sidebar sidebar-dark-primary elevation-4">

      <a href="#" class="brand-link">
        <span class="brand-text font-weight-light">patrician.one</span>
      </a>
      <div class="sidebar">
        <!-- Sidebar user panel (optional) -->
        <div class="user-panel mt-3 pb-3 mb-3 d-flex">

          <div class="info">
            <p class="d-block" style="color:white;">Имя пользователя</p>
            <a href="#" @click="logout" class="d-block">Выйти из системы</a>
          </div>
        </div>



        <!-- Sidebar Menu -->
        <nav class="mt-2">
          <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">


            <li class="nav-header"></li>
            <li class="nav-item">
              <router-link :to="{ name: 'users' }" class="nav-link">
                <i class="nav-icon fas fa-calendar-alt"></i>
                <p>
                  Пользователи
                </p>
              </router-link>
            </li>
            <li class="nav-item">
              <a href="#" class="nav-link">
                <i class="nav-icon far fa-image"></i>
                <p>
                  Модерация справок
                </p>
              </a>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'labolatory' }" class="nav-link">
                <i class="nav-icon fas fa-columns"></i>
                <p>
                  Редактирование лабораторий
                </p>
              </router-link>
            </li>
            <li class="nav-item">
              <a href="#" class="nav-link">
                <i class="nav-icon far fa-envelope"></i>
                <p>
                  Cправочники
                  <i class="fas fa-angle-left right"></i>
                </p>
              </a>
              <ul class="nav nav-treeview">
                <li class="nav-item">
                  <router-link :to="{ name: 'orientation' }" class="nav-link">
                    <i class="far fa-circle nav-icon"></i>
                    <p>Ориентации</p>
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link :to="{ name: 'region' }" class="nav-link">
                    <i class="far fa-circle nav-icon"></i>
                    <p>Регионы</p>
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link :to="{ name: 'city' }" class="nav-link">
                    <i class="far fa-circle nav-icon"></i>
                    <p>Города</p>
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link :to="{ name: 'gender' }" class="nav-link">
                    <i class="far fa-circle nav-icon"></i>
                    <p>Гендоры</p>
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link :to="{ name: 'analyses' }" class="nav-link">
                    <i class="far fa-circle nav-icon"></i>
                    <p>Анализы</p>
                  </router-link>
                </li>
              </ul>
            </li>

          </ul>
        </nav>
      </div>
    </aside>

    <div class="content-wrapper">
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0">Dashboard v3</h1>
            </div>
          </div>
        </div>
      </div>

      <div class="content">
        <div class="container-fluid">
          <div class="row">
            <v-app style="width:100%;">
              <router-view />
            </v-app>
          </div>
        </div>
      </div>

    </div>
    <footer class="main-footer">
      <strong>Copyright &copy; 2014-2021 <a href="https://adminlte.io">AdminLTE.io</a>.</strong>
      All rights reserved.
      <div class="float-right d-none d-sm-inline-block">
        <b>Version</b> 3.2.0
      </div>
    </footer>
  </div>
  <router-view v-else />
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
  methods: {
    logout() {
      localStorage.removeItem('JWT_token')
      localStorage.removeItem('exp')
      this.$router.push({ name: 'login' })
    }
  }
};
</script>
