<template>
    <div>
        <CrudTable :route="'city'" :title="'Города'" :headers="headers" :array_name="'cities'" :item="editedItem">
        </CrudTable>
    </div>
</template>
<script>
import CrudTable from '@/components/CrudTable.vue';
export default {
    name: 'CityView',
    components: {
        CrudTable
    },
    data: () => ({
        headers: [
            {
                text: 'ID',
                align: 'start',
                sortable: false,
                value: 'id',
            },
            { text: 'Название города', value: 'name' },
            { text: 'Регион', value: 'region.name', dicts: [], model: 'region_id' },
            { text: 'Популярный', value: 'is_popular', bool: true },
        ],
        desserts: [],
        editedItem: {
            id: 0,
            name: '',
            is_popular: false
        },
    }),
    mounted() {
        this.getItem()
    },
    methods: {
        getItem() {
            this.axios.get('region/', { params: { size: 500 } }).then(response => { this.headers[2].dicts = response.data.regions })
        }
    },
}
</script>