<template>
    <div>
        <CrudTable :route="'analyses'" :title="'Анализы'" :headers="headers" :array_name="'analyses'" :item="editedItem">
        </CrudTable>
    </div>
</template>
<script>
import CrudTable from '@/components/CrudTable.vue';
export default {
    name: 'AnalysesView',
    components: {
        CrudTable
    },
    data: () => ({
        headers: [
            {
                text: 'ID',
                align: 'start',
                sortable: false,
                value: 'id',
            },
            { text: 'Название анализа', value: 'name' },
            { text: 'Описание', value: 'description', textarea: true },
            { text: 'Референсная граница (минимум)', value: 'ref_min', number: true },
            { text: 'Референсная граница (максимум)', value: 'ref_max', number: true },
            { text: 'Единицы измерения', value: 'unit' },
            { text: 'Результат бинарный?', value: 'is_result_bool', bool: true },
        ],
        desserts: [],
        editedItem: {
            id: 0,
            name: '',
            description: null,
            ref_min: null,
            ref_max: null,
            unit: '',
            is_result_bool: false
        },
    })
}
</script>