import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import LoginView from '../views/LoginView.vue'
import DashboardView from '../views/DashboardView.vue'
import OrientationView from '../views/OrientationView.vue'
import RegionView from '../views/RegionView.vue'
import CityView from '../views/CityView.vue'
import GenderView from '../views/GenderView.vue'
import LabolatoryView from '../views/LabolatoryView.vue'
import AnalysesView from '../views/AnalysesView.vue'
import UsersView from '../views/UsersView.vue'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/',
    name: 'dashboard',
    component: DashboardView
  },
  {
    path: '/dict/orientation',
    name: 'orientation',
    component: OrientationView
  },
  {
    path: '/dict/region',
    name: 'region',
    component: RegionView
  },
  {
    path: '/dict/city',
    name: 'city',
    component: CityView
  },
  {
    path: '/dict/gender',
    name: 'gender',
    component: GenderView
  },
  {
    path: '/dict/labolatories',
    name: 'labolatory',
    component: LabolatoryView
  },
  {
    path: '/dict/analyses',
    name: 'analyses',
    component: AnalysesView
  }
  ,
  {
    path: '/users',
    name: 'users',
    component: UsersView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach(async (to, from, next) => {
  let isAuthenticated = false;
  if (localStorage.getItem("JWT_token")) {
    if ((localStorage.getItem('exp') - (new Date().getTime() / 1000)) >= 0) {
      isAuthenticated = true
    }
  }
  console.log(isAuthenticated)
  if (to.name !== 'login' && !isAuthenticated) next({ name: 'login' })
  else next()
})
export default router
