<template>
    <div>
        <CrudTable :route="'laboratory'" :title="'Лаболатории'" :headers="headers" :array_name="'labolatories'"
            :item="editedItem">
        </CrudTable>
    </div>
</template>
<script>
import CrudTable from '@/components/CrudTable.vue';
export default {
    name: 'CityView',
    components: {
        CrudTable
    },
    data: () => ({
        headers: [
            {
                text: 'ID',
                align: 'start',
                sortable: false,
                value: 'id',
            },
            { text: 'Название лаболатории', value: 'name' },
            { text: 'Страна', value: 'country.name', dicts: [{ id: 1, name: "Российская Федерация" }], model: 'country_id' },
        ],
        desserts: [],
        editedItem: {
            id: 0,
            name: ''
        },
    })
}
</script>